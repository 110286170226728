.score {
    z-index: $medium;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    background: url(/score/tiled_background.png);
    background-repeat: repeat-x repeat-y;
    background-size: 25%;
    position: absolute;
    left: 0;
    transition: top 0.5s;
    overflow-y: scroll;

    &.hidescore {
        top: 100vh;
    }

    &.showscore {
        top: 0;
    }

    .decorations {

        z-index: $medium;

        .decor {
            position: absolute;
            display: block;
            z-index: $high;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .top_left {
            top: 0;
            left: 0;
            width: 169px;
            height: 144px;
            background-image: url(/score/decorations_top_left.png);
        }

        .top_right {
            top: 0;
            right: 0;
            width: 193px;
            height: 191px;
            background-image: url(/score/decorations_top_right.png);
        }

        .bottom_left {
            bottom: 0;
            left: 0;
            height: 468px;
            width: 240px;
            background-image: url(/score/decorations_bottom_left.png);
        }

        .bottom_right {
            bottom: 0;
            right: 0;
            width: 240px;
            height: 451px;
            background-image: url(/score/decorations_bottom_right.png);
        }
    }
    
    .krispy_kreme_header {
        
        display: block;
        z-index: $highest;
        height: 30px;
        width: 100%;
        position: absolute;
    
        img {
            margin-top: 20px;
            height: 30px;
            width: auto;
        }

        @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
            display: none;
        }
        
        @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
            display: none;
        }
        
        @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
            display: none;
        }

        @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
            display: block;
        }

        @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
            display: block;
        }

        @media only screen and (min-device-width: 360px) and (orientation: portrait) {
            display: block;
        }

        @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
            display: block;
        }
    }
}


$angle: 8px;
$angle_width: 6px;

.squishy_wrapper {
  position: absolute;
  bottom: 35px;
  width: 100%;
  z-index: $highest;
  cursor:'pointer';

  /* Social doesn't show on these devies so anchor button */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    bottom: 5px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    bottom: 5px;
  }

  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    bottom: 5px;
  }

  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
    bottom: 35px;
  }

  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    bottom: 35px;
  }

  @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    bottom: 35px;
  }

  @media only screen and (min-device-width: 360px) and (orientation: portrait) {
    bottom: 35px;
  }
  
}

.squishy {
  font-family: "AreteMono";
  font-size: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 6px;
  z-index: $high;
  transition: all .15s linear 0s;
  position: relative;
  display: inline-block;
  padding: 12px 35px;
  cursor:'pointer';
  
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    padding: 12px 25px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    padding: 12px 25px;
  }

  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    padding: 12px 25px;
  }
  
  background-color: $krispy_green;
  border: 4px solid $krispy_white;
  text-transform: uppercase;
  color: $krispy_white;
  letter-spacing: 0px;
  box-shadow: -6px 6px 0 $krispy_white;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    margin-top: 5px;
    margin-bottom: 5px;
  }

  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    margin-top: 5px;
    margin-bottom: 5px;
  }

  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &::after {
    transition: all .15s linear 0s;
    content: '';
    position: absolute;
    top: -2.8px;
    left: -8px;
    width: $angle_width;
    height: $angle;
    background-color: $krispy_white;
    transform: rotate(45deg);
    z-index: $negative;
  }
  
  &::before {
    transition: all .15s linear 0s;
    content: '';
    position: absolute;
    bottom: -8.5px;
    right: -2px;
    width: $angle_width;
    height: $angle;
    background-color: $krispy_white;
    transform: rotate(45deg);
    z-index: $negative;
  }

  &.pink {
    background-color: $krispy_pink;
  }
  
  &.game_over {
    padding: 12px 10px;
    font-size: 20px;
  }

  &.score_inc {
    padding-top: 3px;
    padding-bottom: 3px;
    padding: 3px 10px;
    span {
      font-size: 14px;
      display: block;
      color: $krispy_yellow;
    }
  }
}

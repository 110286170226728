.console {

    .switchmute {
        position: absolute;
        background: black;
        height: 50px;
        width: 100px;
        left: calc(50% - 25px);

        &.mutefalse {
            background: transparent url(/controller/console/speaker_grill.png);
            background-repeat: no-repeat;
            background-size: 50%;
        }

        &.mutetrue {
            background: transparent url(/controller/console/speaker_gum.png);
            background-repeat: no-repeat;
            background-size: 50%;
        }
    }

    .switchhelp {
        
        position: absolute;
        background: black;
        height: 23px;
        width: 96px;
        right: -25px;
        top: 12px;

        &.helpfalse {
            background: transparent url(/controller/console/help_up.png);
            background-repeat: no-repeat;
            background-size: 50%;
        }

        &.helptrue {
            background: transparent url(/controller/console/help_down.png);
            background-repeat: no-repeat;
            background-size: 50%;
        }
    }

    background: url(/controller/console/console_bg.jpg);
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
}
.window {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    z-index: $highest;
}

.application {
    position: relative;
    display: block;
    z-index: $high;
    width: calc(100% - 50px);
    margin-right: 25px;
    margin-left: 25px;
    margin-top: 80px;
    margin-bottom: 36px;
    padding-bottom: 10px;
    background: $krispy_pink;
    display: block;
    box-shadow: -4px 4px 0 rgba($color: #000000, $alpha: 0.2);
    animation: applicationOpen .7s steps(2);
    transform: scale(0);
    -webkit-transform: scale(0);
    animation-fill-mode: forwards;
    animation-delay: .5s;

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
        margin-top: 10px;
        margin-bottom: 15px;
    }
    
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
        margin-top: 15px;
        margin-bottom: 20px;
    }
    
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
        margin-top: 20px;
        margin-bottom: 20px;
    }

    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
        margin-top: 70px;
        margin-bottom: 36px;
    }

    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
        margin-top: 70px;
        margin-bottom: 36px;
    }

    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
        margin-top: 70px;
        margin-bottom: 36px;
    }
    
    @media only screen and (min-device-width: 360px) and (orientation: portrait) {
        margin-top: 70px;
        margin-bottom: 36px;
    }
        
    &.application_close {
        animation: applicationClose .7s steps(2);
    }
    

    @keyframes applicationOpen {
        0%   {
            transform: scale(0);
            -webkit-transform: scale(0);
        }
        25%  {
            transform: scale(0.25);
            -webkit-transform: scale(0.25);
        }
        50%  {
            transform: scale(0.5);
            -webkit-transform: scale(0.5);
        }
        75%  {
            transform: scale(0.75);
            -webkit-transform: scale(0.75);
        }
        100% {
            transform: scale(1);
            -webkit-transform: scale(1);
        }
    }


    @keyframes applicationClose {
        0%   {
            transform: scale(1);
            -webkit-transform: scale(1);
        }
        25%  {
            transform: scale(0.75);
            -webkit-transform: scale(0.75);
        }
        50%  {
            transform: scale(0.5);
            -webkit-transform: scale(0.5);
        }
        75%  {
            transform: scale(0.25);
            -webkit-transform: scale(0.25);
        }
        100% {
            transform: scale(0);
            -webkit-transform: scale(0);
            display: none;
        }
    }

    .header {
        height: 35px;
        width: 100%;
        background: $krispy_white;
        position: relative;

        hr {
            position: absolute;
            top: 3px;
            left: 5%;
            width: 90%;
            height: 5px;
            border: 0;
            border-top: 2px solid $krispy_pink;

            &:nth-child(1) {
                top: 9px;
            }

            &:nth-child(2) {
                top: 15px;
            }
        }

        .lines {
            z-index: $baseline;
        }

        .header_text {       
            position: absolute;
            padding-top: 0px;
            width: 100%;

            p {
                margin: 0;
                padding: 0;
                padding-top: 10px;
                padding-bottom: 8px;
                color: $krispy_pink;
                text-transform: uppercase;
                font-size: 12px;
                font-family: "AreteMono";
                padding-left: 10px;
                padding-right: 10px;
                background: white;
                width: fit-content;
                word-break: break-all;
                margin: auto;
            }
        }

        .close_button {
            height: 16px;
            width: 16px;
            background: $krispy_pink;
            display: block;
            position: absolute;
            right: 5px;
            top: 5px;
            border: $krispy_white 5px solid;

            &:before, &:after {
                position: absolute;
                left: 7px;
                top: 2px;
                content: ' ';
                height: 11px;
                width: 2px;
                background-color: $krispy_white;
            }
            
            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}

.window.share .application .inner_application {
    padding-bottom: 10px;
}
.leaderboard {

    border-collapse: collapse;
    margin-left: 10%;

    .leaderboard_row {
        color: $krispy_white;
        font-family: "AreteMono";
        text-transform: uppercase;

        .place {
            text-align: left;
        }

        .tag {
            text-align: left;
        }
        
        .leader_score {
            text-align: right;
        }

        &.current_user {
            border-top: 4px solid $krispy_yellow;
            border-bottom: 4px solid $krispy_yellow;
        }
    }
}
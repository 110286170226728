.social {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: $high;
    font-family: "AreteMono";

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
        display: none;
    }
    
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
        display: none;
    }
    
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
        display: none;
    }

    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
        display: block;
    }

    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
        display: block;
    }

    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
        display: block;
    }
    
    @media only screen and (min-device-width: 360px) and (orientation: portrait) {
        display: block;
    }
    
    p {
        display: inline-block;
        color: $krispy_white;
        text-transform: uppercase;
        font-size: 14px;
        margin-right: 5px;
        vertical-align: middle;
    }

    a {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 5px;
        margin-right: 5px;
        background-size: 30px 30px;
        vertical-align: middle;
        text-indent: -9999px;
        font-size: 0;

        &.facebook {
            background: url(/generic/facebook.svg);
        }
    
        &.insta {
            background: url(/generic/insta.svg);
        }
    }
}
.krispy_kreme_throwback_form {

    margin-top: 35px;

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
        margin-top: 15px;
    }
    
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
        margin-top: 20px;
    }
    
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
        margin-top: 25px;
    }

    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
        margin-top: 35px;
    }

    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
        margin-top: 35px;
    }

    legend {
        font-family: "AreteMono";
        line-height: 25px;
        font-size: 25px;
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
        color: $krispy_white;
        text-transform: uppercase;

        @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
            font-size: 20px;
            line-height: 20px;
        }
        
        @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
            font-size: 22px;
            line-height: 22px;
        }
        
        @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
            font-size: 22px;
            line-height: 22px;
        }

        text-align: center;
    }

    .form-group {    

        &.check {
            height: 75px;

            @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
                height: 55px;
            }
            
            @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
                height: 55px;
            }
            
            @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
                height: 55px;
            }
        }
        
        position: relative;

        label {
            display: none;
        }

        input[type="text"] {
            font-family: "AreteMono";
            text-transform: uppercase;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-background-clip: padding;
            -moz-background-clip: padding;
            background-clip:padding-box;
            -webkit-border-radius:0;
            -moz-border-radius:0;
            -ms-border-radius:0;
            -o-border-radius:0;
            border-radius:0;
            -webkit-appearance:none;
            outline:0;
            margin:0;
            padding:0;
            text-align: left;
            display: block;
            width: 80%;
            margin-left: 10%;
            margin-top: 20px;
            background: none;
            color: $krispy_white;
            border: none;
            border-bottom: 3px solid $krispy_white;
            -webkit-appearance: none;
            -moz-appearance:    none;
            appearance:         none;
            font-size: 12px;

    
            &::placeholder {
                color: $krispy_white;
            }
        }

        input[type="checkbox"] {
            left: 10%;
            position: absolute;
            top: 12px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: none;
            display: inline-block;
            margin-top: 10px;
            padding: 0 0 0 0px;
            width: 15px;
            height: 15px;
            background: $krispy_white;
            -webkit-appearance: none;
            -moz-appearance:    none;
            appearance:         none;

            &:checked {
                //background: $krispy_yellow;
                background: $krispy_white url(/generic/selected.png);
                background-size: 13px;
                background-position: 1px 2px;
                background-repeat: no-repeat;
            }
        }

        .checkbox_blurb {
            font-family: "AreteMono";
            line-height: 13px;
            left: 18%;
            margin-top: 0;
            position: absolute;
            top: 24px;
            color: $krispy_white;
            font-size: 10px;
            line-height: 10px;
            width: 70%;
            text-align: left;
            
            a {
                color: $krispy_yellow;
            }
        }

        .srv-validation-message {
            font-family: "AreteMono";
            position: absolute;
            top: 0px;
            right: 10%;
            font-size: 12px;
            color: $krispy_yellow;
        }

        &.invalid {
            input[type="text"] {
                border-bottom: 3px solid $krispy_yellow;
            }
        }
    }

    .form-group.check {
        .srv-validation-message {
            top: unset;
            bottom: 0px!important;
        }
    }

    .terms {
        font-family: "AreteMono";
        color: $krispy_white;
        font-size: 10px;
        line-height: 13px;
        width: 80%;
        margin-left: 10%;
        text-align: left;
        
        a {
            color: $krispy_yellow;
        }
    }
}
@import url(https://fonts.googleapis.com/css?family=VT323);
/* Colors */
/* z indexing */
/* Breakpoints */
@media only screen and (min-width: 321px) {
  /* Styles */ }

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */ }

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */ }

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */ }

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */ }

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */ }

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */ }

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */ }

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */ }

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */ }

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */ }

* {
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

html,
body {
  position: fixed;
  overflow: hidden; }

body {
  background-color: #63b1e0;
  margin: 0px; }

.container {
  width: auto;
  text-align: center;
  width: 100vw;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

canvas {
  display: block;
  width: 100%;
  height: 100%;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px; }

.game-area {
  position: absolute;
  top: 40px;
  left: 20px;
  z-index: 100;
  height: calc(100vw - 40px);
  width: calc(100vw - 40px);
  display: block;
  background-image: url(/controller/console/screen_bevel.png);
  background-repeat: no-repeat;
  background-size: cover; }
  .game-area .canvas-wrapper {
    top: 10px;
    left: 10px;
    position: absolute;
    height: calc(100vw - 60px);
    width: calc(100vw - 60px); }

.console {
  background: url(/controller/console/console_bg.jpg);
  width: 100%;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  position: relative; }
  .console .switchmute {
    position: absolute;
    background: black;
    height: 50px;
    width: 100px;
    left: calc(50% - 25px); }
    .console .switchmute.mutefalse {
      background: transparent url(/controller/console/speaker_grill.png);
      background-repeat: no-repeat;
      background-size: 50%; }
    .console .switchmute.mutetrue {
      background: transparent url(/controller/console/speaker_gum.png);
      background-repeat: no-repeat;
      background-size: 50%; }
  .console .switchhelp {
    position: absolute;
    background: black;
    height: 23px;
    width: 96px;
    right: -25px;
    top: 12px; }
    .console .switchhelp.helpfalse {
      background: transparent url(/controller/console/help_up.png);
      background-repeat: no-repeat;
      background-size: 50%; }
    .console .switchhelp.helptrue {
      background: transparent url(/controller/console/help_down.png);
      background-repeat: no-repeat;
      background-size: 50%; }

.stickies {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  pointer-events: none;
  /* iphone 8*/
  /* X */
  /* iphone 8+ */ }
  .stickies .sticker {
    position: absolute;
    display: block;
    z-index: 100;
    background-repeat: no-repeat;
    background-size: cover; }
  .stickies .crack {
    height: 20px;
    width: 23px;
    top: 0px;
    left: 20px;
    background-image: url(/controller/console/crack.png); }
  .stickies .doughnut {
    height: 54px;
    width: 53px;
    bottom: 10px;
    right: 10px;
    background-image: url(/controller/console/doughnut.png);
    z-index: 100; }
  .stickies .haha {
    height: 35px;
    width: 38px;
    bottom: 10px;
    right: 47px;
    background-image: url(/controller/console/haha.png);
    z-index: 101; }
  .stickies .wow {
    height: 65px;
    width: 95px;
    top: calc(100vw + 10px);
    left: calc(50% - 40px);
    background-image: url(/controller/console/wow.png);
    z-index: 101; }
  .stickies .radical {
    height: 60px;
    width: 80px;
    bottom: 10px;
    right: 5px;
    background-image: url(/controller/console/radical.png);
    z-index: 101; }
  .stickies .sheen {
    height: 208px;
    width: 180px;
    top: 7px;
    left: 7px;
    background-image: url(/controller/console/sheen.png);
    z-index: 101;
    opacity: .3; }
  .stickies .krispy_kreme {
    height: 40px;
    width: 103px;
    bottom: 5px;
    left: calc(50vw - 51px);
    background-image: url(/controller/console/krispy_kreme_flat.png);
    z-index: 101; }
  .stickies .scuff_right_bevel {
    height: 100vh;
    width: 34px;
    top: 90px;
    right: 0px;
    background-image: url(/controller/console/right_bevel_scuff.png);
    background-repeat: repeat-y;
    z-index: 101; }
  .stickies .scuff_top_right {
    height: 90px;
    width: 170px;
    top: 0px;
    right: 0px;
    background-image: url(/controller/console/tr_scuff.png);
    z-index: 101; }
  .stickies .scuff_bottom_left {
    height: 129px;
    width: 373px;
    bottom: 0px;
    right: 0px;
    background-image: url(/controller/console/br_scuff.png);
    z-index: 101; }
  .stickies .scuff_bottom_right {
    height: 428px;
    width: 244px;
    bottom: 0px;
    left: 0px;
    background-image: url(/controller/console/bl_scuff.png);
    z-index: 101; }
  @media only screen and (device-width: 320px) and (device-height: 568px) {
    .stickies .radical {
      display: none; }
    .stickies .wow {
      display: none; }
    .stickies .krispy_kreme {
      display: none; } }
  @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    .stickies .radical {
      display: none; }
    .stickies .wow {
      display: none; }
    .stickies .krispy_kreme {
      top: calc(100vw + 10px);
      left: calc(100vw - 140px); } }

.score {
  z-index: 200;
  height: 100vh;
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background: url(/score/tiled_background.png);
  background-repeat: repeat-x repeat-y;
  background-size: 25%;
  position: absolute;
  left: 0;
  transition: top 0.5s;
  overflow-y: scroll; }
  .score.hidescore {
    top: 100vh; }
  .score.showscore {
    top: 0; }
  .score .decorations {
    z-index: 200; }
    .score .decorations .decor {
      position: absolute;
      display: block;
      z-index: 300;
      background-repeat: no-repeat;
      background-size: cover; }
    .score .decorations .top_left {
      top: 0;
      left: 0;
      width: 169px;
      height: 144px;
      background-image: url(/score/decorations_top_left.png); }
    .score .decorations .top_right {
      top: 0;
      right: 0;
      width: 193px;
      height: 191px;
      background-image: url(/score/decorations_top_right.png); }
    .score .decorations .bottom_left {
      bottom: 0;
      left: 0;
      height: 468px;
      width: 240px;
      background-image: url(/score/decorations_bottom_left.png); }
    .score .decorations .bottom_right {
      bottom: 0;
      right: 0;
      width: 240px;
      height: 451px;
      background-image: url(/score/decorations_bottom_right.png); }
  .score .krispy_kreme_header {
    display: block;
    z-index: 1000;
    height: 30px;
    width: 100%;
    position: absolute; }
    .score .krispy_kreme_header img {
      margin-top: 20px;
      height: 30px;
      width: auto; }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      .score .krispy_kreme_header {
        display: none; } }
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
      .score .krispy_kreme_header {
        display: none; } }
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
      .score .krispy_kreme_header {
        display: none; } }
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
      .score .krispy_kreme_header {
        display: block; } }
    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
      .score .krispy_kreme_header {
        display: block; } }
    @media only screen and (min-device-width: 360px) and (orientation: portrait) {
      .score .krispy_kreme_header {
        display: block; } }
    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
      .score .krispy_kreme_header {
        display: block; } }

.desktop {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #efefef; }
  .desktop .desktop_only {
    display: block;
    margin: auto;
    margin-top: 20px; }
    .desktop .desktop_only img {
      width: 610px;
      height: 261px; }
  .desktop .message {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    .desktop .message .logo {
      margin-bottom: 40px;
      width: 250px;
      height: auto; }
    .desktop .message p {
      font-family: "DroidSans";
      max-width: 530px;
      margin: auto; }

@font-face {
  font-family: "DroidSans";
  src: url("/fonts/DroidSans-webfont.eot");
  src: url("/fonts/DroidSans-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/DroidSans-webfont.woff") format("woff"), url("/fonts/DroidSans-webfont.ttf") format("truetype"), url("/fonts/FDroidSans-webfont.svg#DroidSans") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "AreteMono";
  src: url("/fonts/38FAD3_0_0.eot");
  src: url("/fonts/38FAD3_0_0.eot?#iefix") format("embedded-opentype"), url("/fonts/38FAD3_0_0.woff2") format("woff2"), url("/fonts/38FAD3_0_0.woff") format("woff"), url("/fonts/38FAD3_0_0.ttf") format("truetype"); }

@font-face {
  font-family: "SerifGothicStd-Black";
  src: url("/fonts/38FAD3_1_0.eot");
  src: url("/fonts/38FAD3_1_0.eot?#iefix") format("embedded-opentype"), url("/fonts/38FAD3_1_0.woff2") format("woff2"), url("/fonts/38FAD3_1_0.woff") format("woff"), url("/fonts/38FAD3_1_0.ttf") format("truetype"); }

.window {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  z-index: 1000; }

.application {
  position: relative;
  display: block;
  z-index: 300;
  width: calc(100% - 50px);
  margin-right: 25px;
  margin-left: 25px;
  margin-top: 80px;
  margin-bottom: 36px;
  padding-bottom: 10px;
  background: #ea4795;
  display: block;
  box-shadow: -4px 4px 0 rgba(0, 0, 0, 0.2);
  -webkit-animation: applicationOpen 0.7s steps(2);
          animation: applicationOpen 0.7s steps(2);
  transform: scale(0);
  -webkit-transform: scale(0);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: .5s;
          animation-delay: .5s; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .application {
      margin-top: 10px;
      margin-bottom: 15px; } }
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .application {
      margin-top: 15px;
      margin-bottom: 20px; } }
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .application {
      margin-top: 20px;
      margin-bottom: 20px; } }
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .application {
      margin-top: 70px;
      margin-bottom: 36px; } }
  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    .application {
      margin-top: 70px;
      margin-bottom: 36px; } }
  @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    .application {
      margin-top: 70px;
      margin-bottom: 36px; } }
  @media only screen and (min-device-width: 360px) and (orientation: portrait) {
    .application {
      margin-top: 70px;
      margin-bottom: 36px; } }
  .application.application_close {
    -webkit-animation: applicationClose 0.7s steps(2);
            animation: applicationClose 0.7s steps(2); }

@-webkit-keyframes applicationOpen {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  25% {
    transform: scale(0.25);
    -webkit-transform: scale(0.25); }
  50% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5); }
  75% {
    transform: scale(0.75);
    -webkit-transform: scale(0.75); }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

@keyframes applicationOpen {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  25% {
    transform: scale(0.25);
    -webkit-transform: scale(0.25); }
  50% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5); }
  75% {
    transform: scale(0.75);
    -webkit-transform: scale(0.75); }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

@-webkit-keyframes applicationClose {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  25% {
    transform: scale(0.75);
    -webkit-transform: scale(0.75); }
  50% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5); }
  75% {
    transform: scale(0.25);
    -webkit-transform: scale(0.25); }
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
    display: none; } }

@keyframes applicationClose {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  25% {
    transform: scale(0.75);
    -webkit-transform: scale(0.75); }
  50% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5); }
  75% {
    transform: scale(0.25);
    -webkit-transform: scale(0.25); }
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
    display: none; } }
  .application .header {
    height: 35px;
    width: 100%;
    background: #fff;
    position: relative; }
    .application .header hr {
      position: absolute;
      top: 3px;
      left: 5%;
      width: 90%;
      height: 5px;
      border: 0;
      border-top: 2px solid #ea4795; }
      .application .header hr:nth-child(1) {
        top: 9px; }
      .application .header hr:nth-child(2) {
        top: 15px; }
    .application .header .lines {
      z-index: 0; }
    .application .header .header_text {
      position: absolute;
      padding-top: 0px;
      width: 100%; }
      .application .header .header_text p {
        margin: 0;
        padding: 0;
        padding-top: 10px;
        padding-bottom: 8px;
        color: #ea4795;
        text-transform: uppercase;
        font-size: 12px;
        font-family: "AreteMono";
        padding-left: 10px;
        padding-right: 10px;
        background: white;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        word-break: break-all;
        margin: auto; }
    .application .header .close_button {
      height: 16px;
      width: 16px;
      background: #ea4795;
      display: block;
      position: absolute;
      right: 5px;
      top: 5px;
      border: #fff 5px solid; }
      .application .header .close_button:before, .application .header .close_button:after {
        position: absolute;
        left: 7px;
        top: 2px;
        content: ' ';
        height: 11px;
        width: 2px;
        background-color: #fff; }
      .application .header .close_button:before {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
      .application .header .close_button:after {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }

.window.share .application .inner_application {
  padding-bottom: 10px; }

.krispy_kreme_throwback_form {
  margin-top: 35px; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .krispy_kreme_throwback_form {
      margin-top: 15px; } }
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .krispy_kreme_throwback_form {
      margin-top: 20px; } }
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .krispy_kreme_throwback_form {
      margin-top: 25px; } }
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    .krispy_kreme_throwback_form {
      margin-top: 35px; } }
  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    .krispy_kreme_throwback_form {
      margin-top: 35px; } }
  .krispy_kreme_throwback_form legend {
    font-family: "AreteMono";
    line-height: 25px;
    font-size: 25px;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    color: #fff;
    text-transform: uppercase;
    text-align: center; }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      .krispy_kreme_throwback_form legend {
        font-size: 20px;
        line-height: 20px; } }
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
      .krispy_kreme_throwback_form legend {
        font-size: 22px;
        line-height: 22px; } }
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
      .krispy_kreme_throwback_form legend {
        font-size: 22px;
        line-height: 22px; } }
  .krispy_kreme_throwback_form .form-group {
    position: relative; }
    .krispy_kreme_throwback_form .form-group.check {
      height: 75px; }
      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
        .krispy_kreme_throwback_form .form-group.check {
          height: 55px; } }
      @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
        .krispy_kreme_throwback_form .form-group.check {
          height: 55px; } }
      @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
        .krispy_kreme_throwback_form .form-group.check {
          height: 55px; } }
    .krispy_kreme_throwback_form .form-group label {
      display: none; }
    .krispy_kreme_throwback_form .form-group input[type="text"] {
      font-family: "AreteMono";
      text-transform: uppercase;
      box-sizing: border-box;
      -webkit-background-clip: padding;
      -moz-background-clip: padding;
      background-clip: padding-box;
      border-radius: 0;
      -webkit-appearance: none;
      outline: 0;
      margin: 0;
      padding: 0;
      text-align: left;
      display: block;
      width: 80%;
      margin-left: 10%;
      margin-top: 20px;
      background: none;
      color: #fff;
      border: none;
      border-bottom: 3px solid #fff;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 12px; }
      .krispy_kreme_throwback_form .form-group input[type="text"]::-webkit-input-placeholder {
        color: #fff; }
      .krispy_kreme_throwback_form .form-group input[type="text"]::-ms-input-placeholder {
        color: #fff; }
      .krispy_kreme_throwback_form .form-group input[type="text"]::placeholder {
        color: #fff; }
    .krispy_kreme_throwback_form .form-group input[type="checkbox"] {
      left: 10%;
      position: absolute;
      top: 12px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      display: inline-block;
      margin-top: 10px;
      padding: 0 0 0 0px;
      width: 15px;
      height: 15px;
      background: #fff;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
      .krispy_kreme_throwback_form .form-group input[type="checkbox"]:checked {
        background: #fff url(/generic/selected.png);
        background-size: 13px;
        background-position: 1px 2px;
        background-repeat: no-repeat; }
    .krispy_kreme_throwback_form .form-group .checkbox_blurb {
      font-family: "AreteMono";
      line-height: 13px;
      left: 18%;
      margin-top: 0;
      position: absolute;
      top: 24px;
      color: #fff;
      font-size: 10px;
      line-height: 10px;
      width: 70%;
      text-align: left; }
      .krispy_kreme_throwback_form .form-group .checkbox_blurb a {
        color: #fce97c; }
    .krispy_kreme_throwback_form .form-group .srv-validation-message {
      font-family: "AreteMono";
      position: absolute;
      top: 0px;
      right: 10%;
      font-size: 12px;
      color: #fce97c; }
    .krispy_kreme_throwback_form .form-group.invalid input[type="text"] {
      border-bottom: 3px solid #fce97c; }
  .krispy_kreme_throwback_form .form-group.check .srv-validation-message {
    top: unset;
    bottom: 0px !important; }
  .krispy_kreme_throwback_form .terms {
    font-family: "AreteMono";
    color: #fff;
    font-size: 10px;
    line-height: 13px;
    width: 80%;
    margin-left: 10%;
    text-align: left; }
    .krispy_kreme_throwback_form .terms a {
      color: #fce97c; }

.inner_application {
  text-align: center;
  padding-bottom: 30px; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .inner_application {
      padding-bottom: 0px; } }
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .inner_application {
      padding-bottom: 0px; } }
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .inner_application {
      padding-bottom: 0px; } }
  .inner_application .inner_application_headline {
    font-weight: normal;
    font-family: "AreteMono";
    text-transform: uppercase;
    font-size: 20px;
    margin-top: 15px;
    color: #fff; }
  .inner_application .inner_application_headline_varient {
    font-family: "SerifGothicStd-Black";
    text-transform: uppercase;
    font-size: 37px;
    line-height: 35px;
    margin-bottom: 15px;
    margin-top: 15px;
    color: #fff; }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      .inner_application .inner_application_headline_varient {
        margin-bottom: 10px; } }
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
      .inner_application .inner_application_headline_varient {
        margin-bottom: 10px; } }
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
      .inner_application .inner_application_headline_varient {
        margin-bottom: 10px; } }
  .inner_application .buttons .wrapper {
    vertical-align: top;
    width: 50%;
    display: inline-block; }
    @media only screen and (max-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      .inner_application .buttons .wrapper {
        width: 100%;
        display: block; } }
    @media only screen and (max-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
      .inner_application .buttons .wrapper {
        width: 100%;
        display: block; } }
  .inner_application .prizes {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px; }
    .inner_application .prizes img {
      width: 100%;
      height: auto; }
  .inner_application .score_readout {
    font-family: "SerifGothicStd-Black";
    font-size: 34px;
    letter-spacing: 1.5px;
    margin-bottom: 15px;
    margin-top: 15px;
    color: #fff; }
    .inner_application .score_readout span {
      color: #fce97c; }
    @media only screen and (min-device-width: 320px) {
      .inner_application .score_readout {
        font-size: 26px; } }
    @media only screen and (min-device-width: 414px) {
      .inner_application .score_readout {
        font-size: 34px; } }
  .inner_application .inner_application_body {
    font-weight: normal;
    font-family: "AreteMono";
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 25px;
    text-align: center;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%; }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      .inner_application .inner_application_body {
        padding-bottom: 10px; } }
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
      .inner_application .inner_application_body {
        padding-bottom: 10px; } }
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
      .inner_application .inner_application_body {
        padding-bottom: 10px; } }
    .inner_application .inner_application_body span {
      font-family: "AreteMono";
      text-transform: uppercase;
      color: #fce97c; }
  .inner_application .terms {
    margin-top: 20px;
    font-size: 10px;
    color: #fff;
    font-family: "AreteMono"; }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      .inner_application .terms {
        margin-top: 10px; } }
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
      .inner_application .terms {
        margin-top: 10px; } }
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
      .inner_application .terms {
        margin-top: 10px; } }
    .inner_application .terms a {
      color: #fce97c; }
  .inner_application .inner_application_button {
    margin-top: 20px;
    background: #fff;
    font-weight: normal;
    font-family: "AreteMono";
    text-transform: uppercase;
    width: auto;
    font-size: 14px;
    padding: 8px 15px;
    color: #ea4795;
    cursor: pointer; }

.squishy_wrapper {
  position: absolute;
  bottom: 35px;
  width: 100%;
  z-index: 1000;
  cursor: 'pointer';
  /* Social doesn't show on these devies so anchor button */ }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .squishy_wrapper {
      bottom: 5px; } }
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .squishy_wrapper {
      bottom: 5px; } }
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .squishy_wrapper {
      bottom: 5px; } }
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .squishy_wrapper {
      bottom: 35px; } }
  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    .squishy_wrapper {
      bottom: 35px; } }
  @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    .squishy_wrapper {
      bottom: 35px; } }
  @media only screen and (min-device-width: 360px) and (orientation: portrait) {
    .squishy_wrapper {
      bottom: 35px; } }

.squishy {
  font-family: "AreteMono";
  font-size: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 6px;
  z-index: 300;
  transition: all .15s linear 0s;
  position: relative;
  display: inline-block;
  padding: 12px 35px;
  cursor: 'pointer';
  background-color: #098377;
  border: 4px solid #fff;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0px;
  box-shadow: -6px 6px 0 #fff;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .squishy {
      padding: 12px 25px; } }
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .squishy {
      padding: 12px 25px; } }
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .squishy {
      padding: 12px 25px; } }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .squishy {
      margin-top: 5px;
      margin-bottom: 5px; } }
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .squishy {
      margin-top: 5px;
      margin-bottom: 5px; } }
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .squishy {
      margin-top: 5px;
      margin-bottom: 5px; } }
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) {
    .squishy {
      margin-top: 15px;
      margin-bottom: 15px; } }
  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) {
    .squishy {
      margin-top: 15px;
      margin-bottom: 15px; } }
  .squishy::after {
    transition: all .15s linear 0s;
    content: '';
    position: absolute;
    top: -2.8px;
    left: -8px;
    width: 6px;
    height: 8px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    z-index: -1; }
  .squishy::before {
    transition: all .15s linear 0s;
    content: '';
    position: absolute;
    bottom: -8.5px;
    right: -2px;
    width: 6px;
    height: 8px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    z-index: -1; }
  .squishy.pink {
    background-color: #ea4795; }
  .squishy.game_over {
    padding: 12px 10px;
    font-size: 20px; }
  .squishy.score_inc {
    padding-top: 3px;
    padding-bottom: 3px;
    padding: 3px 10px; }
    .squishy.score_inc span {
      font-size: 14px;
      display: block;
      color: #fce97c; }

.social {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 300;
  font-family: "AreteMono"; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .social {
      display: none; } }
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .social {
      display: none; } }
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .social {
      display: none; } }
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    .social {
      display: block; } }
  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    .social {
      display: block; } }
  @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    .social {
      display: block; } }
  @media only screen and (min-device-width: 360px) and (orientation: portrait) {
    .social {
      display: block; } }
  .social p {
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    margin-right: 5px;
    vertical-align: middle; }
  .social a {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-right: 5px;
    background-size: 30px 30px;
    vertical-align: middle;
    text-indent: -9999px;
    font-size: 0; }
    .social a.facebook {
      background: url(/generic/facebook.svg); }
    .social a.insta {
      background: url(/generic/insta.svg); }

.leaderboard {
  border-collapse: collapse;
  margin-left: 10%; }
  .leaderboard .leaderboard_row {
    color: #fff;
    font-family: "AreteMono";
    text-transform: uppercase; }
    .leaderboard .leaderboard_row .place {
      text-align: left; }
    .leaderboard .leaderboard_row .tag {
      text-align: left; }
    .leaderboard .leaderboard_row .leader_score {
      text-align: right; }
    .leaderboard .leaderboard_row.current_user {
      border-top: 4px solid #fce97c;
      border-bottom: 4px solid #fce97c; }

.debug {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999999999;
  color: red; }


.stickies {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    pointer-events: none;

    .sticker {
        position: absolute;
        display: block;
        z-index: 100;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .crack {
        height: 20px;
        width: 23px;
        top: 0px;
        left: 20px;
        background-image: url(/controller/console/crack.png);
    }

    .doughnut {
        height: 54px;
        width: 53px;
        bottom: 10px;
        right: 10px;
        background-image: url(/controller/console/doughnut.png);
        z-index: 100;
    }

    .haha {
        height: 35px;
        width: 38px;
        bottom: 10px;
        right: 47px;
        background-image: url(/controller/console/haha.png); 
        z-index: 101;
    }

    .wow {
        height: 65px;
        width: 95px;
        top: calc(100vw + 10px);
        left: calc(50% - 40px);
        background-image: url(/controller/console/wow.png); 
        z-index: 101;
    }

    .radical {
        height: 60px;
        width: 80px;
        bottom: 10px;
        right: 5px;
        background-image: url(/controller/console/radical.png); 
        z-index: 101;
    }

    .sheen {
        height: 208px;
        width: 180px;
        top: 7px;
        left: 7px;
        background-image: url(/controller/console/sheen.png); 
        z-index: 101;
        opacity: .3;
    }

    .krispy_kreme {
        height: 40px;
        width:103px;
        bottom: 5px;
        left: calc(50vw - 51px);
        background-image: url(/controller/console/krispy_kreme_flat.png); 
        z-index: 101;
    }

    .scuff_right_bevel {
        height: 100vh;
        width: 34px;
        top: 90px;
        right: 0px;
        background-image: url(/controller/console/right_bevel_scuff.png); 
        background-repeat: repeat-y;
        z-index: 101;
    }

    .scuff_top_right {
        height: 90px;
        width: 170px;
        top: 0px;
        right: 0px;
        background-image: url(/controller/console/tr_scuff.png); 
        z-index: 101;
    }

    .scuff_bottom_left {
        height: 129px;
        width: 373px;
        bottom: 0px;
        right: 0px;
        background-image: url(/controller/console/br_scuff.png); 
        z-index: 101;
    }

    .scuff_bottom_right {
        height: 428px;
        width: 244px;
        bottom: 0px;
        left: 0px;
        background-image: url(/controller/console/bl_scuff.png); 
        z-index: 101;
    }   

    @media only screen 
    and (device-width : 320px) 
    and (device-height : 568px)  {
        .radical {
            display: none;
        }

        .wow {
            display: none;
        }

        .krispy_kreme {
            // top: calc(100vw + 10px);
            // left: calc(100vw - 140px);
            display: none;
        }
    }

    /* iphone 8*/
    @media only screen 
    and (device-width : 375px) 
    and (device-height : 667px) 
    and (-webkit-device-pixel-ratio : 2) {
        .radical {
            display: none;
        }

        .wow {
            display: none;
        }

        .krispy_kreme {
            top: calc(100vw + 10px);
            left: calc(100vw - 140px);
        }
    }

    /* X */
    @media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) {
        
    }

    /* iphone 8+ */
    @media only screen 
    and (device-width : 414px) 
    and (device-height : 736px) 
    and (-webkit-device-pixel-ratio : 3) { 

    }
}
.game-area {
    position: absolute;
    top: 40px;
    left: 20px;
    z-index: $low;
    height: calc(100vw - 40px);
    width: calc(100vw - 40px);
    display: block;
    background-image: url(/controller/console/screen_bevel.png);
    background-repeat: no-repeat;
    background-size: cover;

    .canvas-wrapper {
        top: 10px;
        left: 10px;
        position: absolute;
        height: calc(100vw - 60px);
        width: calc(100vw - 60px);
    }
}
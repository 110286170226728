@font-face {
  font-family: "DroidSans";
  src: url("/fonts/DroidSans-webfont.eot");
  src: url("/fonts/DroidSans-webfont.eot?#iefix") format("embedded-opentype"),
    url("/fonts/DroidSans-webfont.woff") format("woff"),
    url("/fonts/DroidSans-webfont.ttf") format("truetype"),
    url("/fonts/FDroidSans-webfont.svg#DroidSans") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AreteMono";
  src: url("/fonts/38FAD3_0_0.eot");
  src: url("/fonts/38FAD3_0_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/38FAD3_0_0.woff2") format("woff2"),
    url("/fonts/38FAD3_0_0.woff") format("woff"),
    url("/fonts/38FAD3_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "SerifGothicStd-Black";
  src: url("/fonts/38FAD3_1_0.eot");
  src: url("/fonts/38FAD3_1_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/38FAD3_1_0.woff2") format("woff2"),
    url("/fonts/38FAD3_1_0.woff") format("woff"),
    url("/fonts/38FAD3_1_0.ttf") format("truetype");
}

* {
	  -webkit-touch-callout: none;
	  -webkit-text-size-adjust: none;
	  -webkit-tap-highlight-color: rgba(0,0,0,0); 
	  // -webkit-user-select: none;
	  -webkit-tap-highlight-color: rgba(0,0,0,0); 
}

html,
body {
  position: fixed;
  overflow: hidden;
}

body {
    background-color: #63b1e0;
    margin: 0px;
}

.container {
    width:auto;
    text-align:center;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
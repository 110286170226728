.inner_application {
    
    text-align: center;
    padding-bottom: 30px;

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
        padding-bottom: 0px;  
    }
    
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
        padding-bottom: 0px;  
    }
    
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
        padding-bottom: 0px;  
    }

    .inner_application_headline {
        font-weight: normal;
        font-family: "AreteMono";
        text-transform: uppercase;
        font-size: 20px;
        margin-top: 15px;
        color: $krispy_white;
    }

    .inner_application_headline_varient {
        font-family: "SerifGothicStd-Black";
        text-transform: uppercase;
        font-size: 37px;
        line-height: 35px;
        margin-bottom: 15px;

        @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
            margin-bottom: 10px;
        }
        
        @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
            margin-bottom: 10px;
        }
        
        @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
            margin-bottom: 10px;
        }
        
        margin-top: 15px;
        color: $krispy_white;
    }

    .buttons {
        .wrapper {
            vertical-align: top;
            width: 50%;
            display: inline-block;

            @media only screen and (max-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
                width: 100%;
                display: block;
            }
            
            @media only screen and (max-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
                width: 100%;
                display: block;
            }
        }
    }

    .prizes {
        
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        margin-bottom: 10px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .score_readout {
        font-family: "SerifGothicStd-Black";
        font-size: 34px;
        letter-spacing: 1.5px;
        margin-bottom: 15px;
        margin-top: 15px;
        color: $krispy_white;

        span {
            color: $krispy_yellow;
        }

        @media only screen and (min-device-width : 320px)  {
            font-size: 26px;
        }

        @media only screen and (min-device-width : 414px)  {
            font-size: 34px;
        }
        
    }

    .inner_application_body {
        font-weight: normal;
        font-family: "AreteMono";
        font-size: 14px;
        color: $krispy_white;
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: 25px;

        @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
            padding-bottom: 10px;  
        }
        
        @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
            padding-bottom: 10px;  
        }
        
        @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
            padding-bottom: 10px;  
        }


        text-align: center;
        width: 80%;
        padding-left: 10%;
        padding-right: 10%;

        span {
            font-family: "AreteMono";
            text-transform: uppercase;
            color: $krispy_yellow;
        }
    }

    .terms {
        margin-top: 20px;

        @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
            margin-top: 10px;  
        }
        
        @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
            margin-top: 10px;  
        }
        
        @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
            margin-top: 10px;  
        }


        font-size: 10px;
        color: $krispy_white;
        font-family: "AreteMono";

        a {
            color: $krispy_yellow;
        }
    }

    .inner_application_button {
        margin-top: 20px;
        background: $krispy_white;
        font-weight: normal;
        font-family: "AreteMono";
        text-transform: uppercase;
        width: auto;
        font-size: 14px;
        padding: 8px 15px;
        color: $krispy_pink;
        cursor: pointer;
    }
}
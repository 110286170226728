@import "./Styles/Variables.scss";
@import "./Styles/Body";
@import "./Styles/Canvas";
@import "./Styles/GameArea";
@import "./Styles/Console";
@import "./Styles/Stickies.scss";
@import "./Styles/Score.scss";
@import "./Styles/Desktop.scss";
@import "./Styles/Fonts.scss";
@import "./Styles/Application.scss";

@import "./Styles/Applications/SignUp.scss";
@import "./Styles/Applications/YourScore.scss";
@import "./Styles/Applications/Squishy.scss";
@import "./Styles/Applications/Social.scss";
@import "./Styles/Applications/Leaderboard.scss";



.debug {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999999999;
    color: red;
}
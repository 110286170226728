.desktop {
    z-index: $highest;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #efefef;

    .desktop_only {
        display: block;
        margin: auto;
        margin-top: 20px;

        img {
            width: 610px;
            height: 261px;
        }

    }

    .message {

        position: relative;
        top: 50%;
        transform: translateY(-50%);

        .logo {
            margin-bottom: 40px;
            width: 250px;
            height: auto;
        }

        p {
            font-family: "DroidSans";
            max-width: 530px;
            margin: auto;
        }
    }   
}